import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ReviewCard from '../components/ReviewCard';
import SEO from '../components/SEO';

const TitleStyles = styled.h1`
  padding-top: 3rem;
  text-align: center;
`;

export default function TestimonialsPage(props) {
  const { nodes } = props.data.testimonials;
  return (
    <>
      <SEO title={'Testimonials'} />
      <TitleStyles>Client Reviews</TitleStyles>
      {nodes.map((node) => {
        return <ReviewCard review={node} key={node.pet} />;
      })}
    </>
  );
}

export const query = graphql`
  query TestimonialQuery {
    testimonials: allSanityTestimonial {
      nodes {
        name
        pet
        testimonial
        image {
          asset {
            gatsbyImageData(width: 400, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
