import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const CardContainerStyles = styled.div`
  border-top: black solid 1px;
  margin: 3rem;
  &:nth-child(even) div:last-child {
    grid-template-columns: auto 400px;
    order: -1;
  }
  @media (min-width: 800px) {
    margin: 5rem 10rem;
  }
  @media (min-width: 1200px) {
    margin: 5rem 20rem;
  }
  @media (min-width: 1500px) {
    margin: 5rem 30rem;
  }
`;

const CardStyles = styled.div`
  margin: 3rem 1.5rem;
  padding-top: 3rem;
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 400px auto;
  }
`;

const ReviewContentStyles = styled.div`
  text-align: center;
  @media (min-width: 1000px) {
    align-items: center;
    margin: 2rem 5rem;
  }
`;

const ReviewTitle = styled.h2`
  margin-top: 2rem;
  padding: 2rem 0;
  text-align: center;
`;

export default function ReviewCard({ review }) {
  return (
    <CardContainerStyles>
      <CardStyles>
        <GatsbyImage
          image={review.image.asset.gatsbyImageData}
          alt={review.pet}
        />
        <ReviewContentStyles>
          <ReviewTitle>
            {review.name} - {review.pet}
          </ReviewTitle>
          <p>{review.testimonial}</p>
        </ReviewContentStyles>
      </CardStyles>
    </CardContainerStyles>
  );
}
